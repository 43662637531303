<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TicketDetails :public="true" :validation="false"/>
      </CCol>
    </CRow>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TicketDetails from '../../components/tickets/TicketDetails.vue'
import tickets from '../../services/tickets';
import store from '../../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/login';

export default {
  name: 'PublicTicket',
  components: { TicketDetails, Loading },
  data: function () {
		return {
        code: 'active',
        title: 'Reportes Activos',
        tickets: []
    }
  },
  mounted: async function() {
    this.loading();

    let token = localStorage.getItem("token");

    if(token != null){
      let response = await ws.validate({token: localStorage.getItem("token")});

      if(response.type == "success"){
        let code = await this.$route.params.code;

        window.location.href = "/#/tickets/"+code+"/view";
      }
    }
    
    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  },
}
</script>
